var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video-list fill-height"},[_c('div',{staticClass:"video-list-container px-16 fill-height pb-55"},[(_vm.isLoaded)?_c('nut-scroller',{ref:"video-list",staticClass:"video-scroller",attrs:{"type":"vertical","is-loading":_vm.isLoading},on:{"loadMore":_vm.loadVideos,"pulldown":_vm.refreshVideos,"scrollChange":_vm.scrollIt}},[(_vm.videos.length)?_c('div',{staticClass:"nut-vert-list",staticStyle:{"padding":"26px 0"},attrs:{"slot":"list"},slot:"list"},[_vm._l((_vm.videos),function(video,index){return [_c('div',{key:`video-${index}`,on:{"click":function($event){return _vm.playThis(video.id)}}},[_c('nut-row',{class:[
                'nut-vert-list-item',
                index !== 0 ? 'mt-15' : '',
                'bg-white',
                'radius-card'
              ]},[_c('nut-col',{attrs:{"span":8}},[_c('div',{staticClass:"height-100",style:({
                    backgroundImage: `url(${video.link})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  })})]),_c('nut-col',{staticClass:"height-100",attrs:{"span":16}},[_c('nut-row',{staticClass:"text-left pa-10 text-primary video-list-item-height position-relative"},[_c('nut-col',{staticClass:"font-bold",attrs:{"span":24}},[_vm._v(_vm._s(video.title))]),_c('nut-col',{staticClass:"position-absolute bottom-3 left-0 pl-10 pr-3",attrs:{"span":24}},[_c('div',{staticClass:"flex justify-between align-center"},[_c('span',{staticClass:"font-medium font-bold"},[_vm._v(_vm._s(video.length))]),_c('nut-icon',{staticStyle:{"z-index":"999999"},attrs:{"type":"self","size":"36px","url":require('@/assets/icons/play_btn.svg')}})],1)])],1)],1)],1)],1)]})],2):_vm._e()]):_c('div',[_c('nut-skeleton',_vm._l((5),function(item,index){return _c('row',{key:index,attrs:{"padding":"15px 10px 0"}},[_c('skeleton-square',{attrs:{"width":"80px","height":"80px"}}),_c('column',{attrs:{"padding":"0 0 0 10px","height":"100px"}},[_c('skeleton-square',{attrs:{"width":"200px","margin":"0 0 10px 0"}}),_c('skeleton-square',{attrs:{"width":"100px","margin":"0 0 10px 0"}}),_c('skeleton-square',{attrs:{"color":"#ffffff"}}),_c('skeleton-square',{attrs:{"width":"60px"}})],1),_c('column',{attrs:{"padding":"0 0 0 40px"}},[_c('skeleton-circle',{attrs:{"diameter":"30px","margin":"40px 0 0 0"}})],1)],1)}),1)],1),_c('div',{staticClass:"chapter-selector"},[_c('nut-button',{attrs:{"color":"#8E313C","type":"light"},on:{"click":function($event){_vm.ifShowChapter = !_vm.ifShowChapter}}},[_vm._v(" 选章 ")])],1),_c('nut-popup',{staticStyle:{"width":"70%","height":"100%","border-radius":"5px","background-color":"#8E313C"},attrs:{"position":"left"},model:{value:(_vm.ifShowChapter),callback:function ($$v) {_vm.ifShowChapter=$$v},expression:"ifShowChapter"}},[_c('nut-sidenavbar',{staticClass:"pa-16",attrs:{"show":_vm.ifShowChapter}},_vm._l((_vm.chapters),function(chapter,index){return _c('div',{key:index,class:[
            'text-left',
            index !== _vm.chapters.length - 1 ? 'divider' : ''
          ],staticStyle:{"background-color":"#8E313C"}},[_c('div',{staticClass:"py-10",staticStyle:{"padding-left":"15px","color":"whitesmoke"},on:{"click":function($event){return _vm.toChapter(index)}}},[_c('div',{staticClass:"font-bold d-flex align-center justify-between fill-width"},[_c('span',[_vm._v(_vm._s(chapter.title))]),_c('nut-icon',{attrs:{"type":"self","color":"#ffffff","size":"26px","url":require('@/assets/icons/arrow-right.svg')}})],1),_c('div',{staticClass:"text truncate mt-10"},[_vm._v(_vm._s(chapter.introduction))])])])}),0)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }