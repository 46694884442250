<template>
  <div class="video-list fill-height">
    <div class="video-list-container px-16 fill-height pb-55">
      <nut-scroller
        v-if="isLoaded"
        ref="video-list"
        class="video-scroller"
        type="vertical"
        :is-loading="isLoading"
        @loadMore="loadVideos"
        @pulldown="refreshVideos"
        @scrollChange="scrollIt"
      >
        <div
          v-if="videos.length"
          slot="list"
          class="nut-vert-list"
          style="padding: 26px 0;"
        >
          <template v-for="(video, index) in videos">
            <div :key="`video-${index}`" @click="playThis(video.id)">
              <nut-row
                :class="[
                  'nut-vert-list-item',
                  index !== 0 ? 'mt-15' : '',
                  'bg-white',
                  'radius-card'
                ]"
              >
                <nut-col :span="8">
                  <div
                    class="height-100"
                    :style="{
                      backgroundImage: `url(${video.link})`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover'
                    }"
                  ></div>
                </nut-col>
                <nut-col :span="16" class="height-100">
                  <nut-row
                    class="text-left pa-10 text-primary video-list-item-height position-relative"
                  >
                    <nut-col :span="24" class="font-bold">{{
                      video.title
                    }}</nut-col>
                    <nut-col
                      :span="24"
                      class="position-absolute bottom-3 left-0 pl-10 pr-3"
                    >
                      <div class="flex justify-between align-center">
                        <span class="font-medium font-bold">{{
                          video.length
                        }}</span>
                        <nut-icon
                          type="self"
                          size="36px"
                          style="z-index: 999999"
                          :url="require('@/assets/icons/play_btn.svg')"
                        ></nut-icon>
                      </div>
                    </nut-col>
                  </nut-row>
                </nut-col>
              </nut-row>
            </div>
          </template>
        </div>
      </nut-scroller>
      <div v-else>
        <nut-skeleton>
          <row padding="15px 10px 0" v-for="(item, index) in 5" :key="index">
            <skeleton-square width="80px" height="80px"></skeleton-square>
            <column padding="0 0 0 10px" height="100px">
              <skeleton-square
                width="200px"
                margin="0 0 10px 0"
              ></skeleton-square>
              <skeleton-square
                width="100px"
                margin="0 0 10px 0"
              ></skeleton-square>
              <skeleton-square color="#ffffff"></skeleton-square>
              <skeleton-square width="60px"></skeleton-square>
            </column>
            <column padding="0 0 0 40px">
              <skeleton-circle
                diameter="30px"
                margin="40px 0 0 0"
              ></skeleton-circle>
            </column>
          </row>
        </nut-skeleton>
      </div>
      <div class="chapter-selector">
        <!--        <nut-drag direction="y" :style="{ right: '0px', top: '500px' }">-->
        <!--          <nut-fixednav :active="myActive" @selected="selectMenu">-->
        <!--            <ul slot="list" class="fixed-list">-->
        <!--              <li-->
        <!--                style="border-right: 1px solid #e2e2e2; padding-right: 10px;"-->
        <!--                @click="showChapters"-->
        <!--              >-->
        <!--                章节视频-->
        <!--              </li>-->
        <!--              <li class="px-10">所有视频</li>-->
        <!--            </ul>-->
        <!--            <template slot="btn" @click="myActive = !myActive">-->
        <!--              <span>{{ myActive ? "关闭" : "章节选择" }}</span>-->
        <!--            </template>-->
        <!--          </nut-fixednav>-->
        <!--        </nut-drag>-->
        <nut-button
          color="#8E313C"
          type="light"
          @click="ifShowChapter = !ifShowChapter"
        >
          选章
        </nut-button>
        <!--        <nut-icon-->
        <!--          class="chapter-selector-clear"-->
        <!--          type="circle-cross"-->
        <!--          size="20px"-->
        <!--          color="red"-->
        <!--          @click="clearChapters"-->
        <!--        ></nut-icon>-->
      </div>
      <nut-popup
        position="left"
        v-model="ifShowChapter"
        style="width: 70%; height: 100%; border-radius: 5px; background-color: #8E313C;"
      >
        <nut-sidenavbar :show="ifShowChapter" class="pa-16">
          <div
            :class="[
              'text-left',
              index !== chapters.length - 1 ? 'divider' : ''
            ]"
            style="background-color: #8E313C;"
            v-for="(chapter, index) in chapters"
            :key="index"
          >
            <div
              class="py-10"
              style="padding-left: 15px; color: whitesmoke;"
              @click="toChapter(index)"
            >
              <div
                class="font-bold d-flex align-center justify-between fill-width"
              >
                <span>{{ chapter.title }}</span>
                <nut-icon
                  type="self"
                  color="#ffffff"
                  size="26px"
                  :url="require('@/assets/icons/arrow-right.svg')"
                ></nut-icon>
              </div>
              <div class="text truncate mt-10">{{ chapter.introduction }}</div>
            </div>
          </div>
        </nut-sidenavbar>
      </nut-popup>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "List",
  title() {
    return `${this.$t("list.pageTitle")}`;
  },
  data() {
    return {
      ifShowChapter: false,
      isLoaded: false,
      inChapter: false,
      isLoading: false,
      currentVideoPage: 1,
      currentChapterPage: 1,
      currentChapterId: null,
      myActive: false
    };
  },

  computed: {
    ...mapGetters("video", {
      chapters: "getChapters",
      chapterMeta: "getChapterMeta",
      videoMeta: "getVideoMeta"
    }),

    videos: {
      get() {
        return this.$store.getters["video/getVideos"];
      },
      set(val) {
        this.$store.dispatch("video/setVideos", val);
      }
    }
  },
  watch: {
    currentChapterId() {
      this.currentVideoPage = 1;
      this.getVideos();
    }
  },
  created() {
    this.getVideos();
  },
  methods: {
    selectMenu(e) {
      console.log(e);
    },

    showChapters() {
      this.ifShowChapter = true;
      this.myActive = false;
    },

    clearChapters() {
      this.currentChapterId = null;
      this.$router.push({ name: "List" }).then(() => {
        location.reload();
      });
    },

    getVideos() {
      this.setVideos(this.getParams()).then(() => {
        this.isLoaded = true;
      });
      this.setChapters({ page: this.currentChapterPage });
    },

    getParams() {
      let params = {
        page: this.currentVideoPage
      };
      if (this.currentChapterId) {
        params.chapterId = this.currentChapterId;
      } else if (this.$route.query.chapterId) {
        params.chapterId = this.$route.query.chapterId;
        this.currentChapterId = params.chapterId;
      }

      return params;
    },

    toChapter(index) {
      this.currentChapterId = this.chapters[index].id;

      this.$router
        .push({
          name: "List",
          query: { chapterId: this.currentChapterId }
        })
        .then(() => {
          location.reload();
        });
      console.log(this.$refs["video-list"]);
      this.$refs["video-list"].pulldown();
    },

    playThis(id) {
      this.$router.push({ path: `/video/${id}` });
    },

    loadVideos() {
      if (
        this.videoMeta !== "undefined" &&
        this.videoMeta.last_page > this.currentVideoPage
      ) {
        this.setVideos(this.getParams()).then(() => {
          this.currentVideoPage++;
        });
      }
    },

    refreshVideos() {
      this.currentVideoPage = 1;
      this.isLoading = true;

      this.setVideos(this.getParams()).then(() => {
        this.isLoading = false;
        this.ifShowChapter = false;
      });
    },

    scrollIt() {},

    ...mapActions("video", ["setChapters", "setVideos"])
  }
};
</script>

<style scoped lang="scss">
.video-list-item-height {
  height: calc(100% - 20px);
}

.video-list-skeleton-loader {
  width: 100px;
  height: 100px;
  margin: 0;
}

.video-scroller {
  .nut-vert-list {
    margin-bottom: 80px;
  }
}

.chapter-selector {
  position: absolute;
  right: 10px;
  bottom: 80px;
  z-index: 999;
  .nut-button {
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  .small {
    font-size: 13px;
    font-weight: bold;
  }
}
</style>
